import 'aframe/dist/aframe-master';
import 'mind-ar/dist/mindar-image-aframe.prod';
import 'aframe-extras/dist/aframe-extras';
import $ from 'jquery';

$(document).ready(() => {
    console.log("ready");

    const sceneEl = document.querySelector('a-scene');
    let arSystem;

    const btn1 = document.querySelector('#btn-1');
    const btn2 = document.querySelector('#btn-2');
    const btn3 = document.querySelector('#btn-3');

    const avatarModel = document.querySelector('#char');

    // https://github.com/hiukim/mind-ar-js
    sceneEl.addEventListener('loaded', function () {
        arSystem = sceneEl.systems["mindar-image-system"];
        console.log("ARSystem loaded");
    });

    btn1.addEventListener('click', () => {
        // alert('btn 1');
        avatarModel.setAttribute('animation-mixer', { timeScale: 0 });
    })
    btn2.addEventListener('click', () => {
        // alert('btn 2');
        avatarModel.setAttribute('animation-mixer', { timeScale: .5 });
    })
    btn3.addEventListener('click', () => {
        // alert('btn 3');
        avatarModel.setAttribute('animation-mixer', { timeScale: 1 });
    })

    // https://hiukim.github.io/mind-ar-js-doc/examples/events-handling
    const exampleTarget = document.querySelector('#example-target');
    exampleTarget.addEventListener("targetFound", event => {
        $('body').addClass('found');
        console.log("target found", event);

        btn1.setAttribute('visible', true);
        btn1.setAttribute('animation', {
            'property': 'position',
            'to': { x: -2.5, y: 0, z: 2 },
        });
        setTimeout(() => {
            btn2.setAttribute('visible', true);
            btn2.setAttribute('animation', {
                'property': 'position',
                'to': { x: 0, y: 0, z: 2 },
                });

        }, 500);
        setTimeout(() => {
            btn3.setAttribute('visible', true);
            btn3.setAttribute('animation', {
                'property': 'position',
                'to': { x: 2.5, y: 0, z: 2 },
                });

        }, 1000);
    });

    exampleTarget.addEventListener("targetLost", event => {
        $('body').removeClass('found');
        console.log("target lost", event);

        btn1.setAttribute('visible', false);
        btn2.setAttribute('visible', false);
        btn3.setAttribute('visible', false);
    });

    var camIsOn = true;
    $('button').click(() => {
        camIsOn = !camIsOn;
        console.log('toggleCam', camIsOn);
        if (camIsOn) {
            arSystem.start();
        }
        else {
            arSystem.stop();
        }
    });
});